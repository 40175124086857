import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;
    display: grid;
    justify-content: center;

    img {
         max-height: 440px;
    }

    ${({doubleImage}) => `${doubleImage && `
        @media ${breakpoint.sm} {
            grid-template-rows: 440px auto;
        }
    `}
    `}


    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;
    }

   /*  img {
        max-height: 440px;
        width: auto;
    } */
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter2;
        position: relative;

        &::before {
            content: counter(my-awesome-counter2) "." ;
            font-weight: 700;
            position: absolute;
        }

        ul li::before {
            content: normal;
        }
    }
`
const DoubleImage = styled.div `
    margin: 1.5rem 0 2.5rem;

    @media ${breakpoint.sm} {
        display: grid;
     gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
    }
`

const Author = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  opacity: 0.75;
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.5rem;
  margin-bottom: 2rem;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Izrael, Jordánsko a Palestina</Title>
            <Info>
                <Author>Simona</Author>
                <Date>16. 4. 2023</Date>
            </Info>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/israel/israel1.jpeg" alt="Al-Khazneh - Pokladnice"></StaticImage>
                <StyledFigcaption>Al-Khazneh - Pokladnice</StyledFigcaption>
            </StyledFigure>
            <p>V roce 2017 jsem se vydala netypicky na zájezd s cestovní kanceláří. Volba padla na CK, protože se jednalo o <b>Izrael/Palestinu a Jordánsko</b>, kam jsem si v té době netroufala vydat se na cestu úplně po vlastní ose.</p>
            <p>Volba padla na nabídku jedné z mála cestovních kanceláří, které v té době zájezdy do těchto destinací nabízely, <a href="https://www.avetour.cz/">CK Avetour</a>.</p>
            <p>Jednalo se o 10 denní zájezd s nabitým programem na každý den. Výhoda určitě byla v česky mluvícím průvodci, panu Hrdličkovi, který byl naprosto fantastický. (<a href="https://www.ckhrdlicka.cz/o-nas">a shodou okolností jsem při psaní článku zjistila, že od roku 2019 má svoji vlastní CK</a>) Pan Hrdlička nám během přejezdů v autobuse mezi jednotlivými místy, postupně odkrýval problematiku Izraelsko-Palestinského konfliktu, historii a zajímavosti týkající se navštěvovaných míst. A jeho služby rozhodně doporučuji.</p>
            <p>První den po příletu nás uvítaly vycházející paprsky nad Mcipe Ramon - takzvaný izraelský "Grand Canyon", jedná se o úchvatnou přírodní scenérii ohromujícího kráteru Ramon uprostřed pouště Negev, který je 45 km dlouhý, 8 km široký a 500 m hluboký. Odpoledne jsme strávili odpočinkem na pláži Rudého moře v Ejlatu, odkud jsme se přesunuli pěšky přes hraniční přechod do Jordánska.</p>
            <p>Proč pěšky? Zkrátka izraelský autobus nesměl přejet hranice do Jordánska a opačně, tedy sbalili jsme si svoje kufry a batohy a přešli hraniční přechod po vlastních.</p>
            <p>Druhý den jsme se po snídani vydali do zhruba hodinu, od města Akaba, vzdálené pouštní oblasti Wádi Rum. Oblast s  pouštními a skalistými scenériemi, díky kterým patří mezi nejkrásnější a nejzajímavější místa v Jordánsku. Od roku 2011 je tato oblast zapsána na seznamu světového dědictví UNESCO. Absolvovali jsme zde projížďku s jeepy, oběd v místní beduínské komunitě a k večeru se přesouvali směr Petra.</p>
            <p><a href="https://cs.wikipedia.org/wiki/Petra_(m%C4%9Bsto)">Skalní město Petra</a>, jedno z hlavních míst, které jsem ve svém životě chtěla vidět. Petra je archeologické naleziště, které je rovněž zapsané na seznamu světového dědictví UNESCO, a to již od roku 1985. Od roku 2007 je Petra také zapsána mezi <a href="https://g.co/kgs/Ayja31">7 novodobých divů světa</a>.</p>
            <p>V Petře jsme strávili celý den. S sebou jsme měli pití a svačinu na celý den. Bylo poněkud teplo a v Petře mezi skalními útesy toho mnoho nachodíte, jsou místa, kde je možné se schovat do stínu, ale například pro výšlap ke klášteru, který vidíte níže na fotografii, se lahev s vodou určitě hodí. Nicméně potěšilo nás, že i v těchto místech je možné si zakoupit menší občerstvení (plechovka Coca-coly, typický čaj se spoustou cukru, který příjemně osvěží a doplní energii).</p>
            <p>Město původně sloužilo jako pohřebiště, následně z něj národ Nabatejců udělal hlavní město svého království. Ve svém největším rozkvětu zde žilo až 30 tisíc obyvatel. Což je pro mě teda neuvěřitelné..</p>
            <DoubleImage>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/israel/israel2.jpeg" alt="Al-Khazneh - Pokladnice"></StaticImage>
                    <StyledFigcaption>Al-Khazneh - Pokladnice</StyledFigcaption>
                </StyledFigure>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/israel/israel3.jpeg" alt="Klášter Ad-Dér, pokud se zamilujete do Pokladnice, nejspíš se vám bude líbit i klášter Ad-Dér – jsou dost podobné, že? Ke klášteru dojdete asi po 3 kilometrech od centra a 800 schodech"></StaticImage>
                    <StyledFigcaption>Klášter Ad-Dér, pokud se zamilujete do Pokladnice, nejspíš se vám bude líbit i klášter Ad-Dér – jsou dost podobné, že? Ke klášteru dojdete asi po 3 kilometrech od centra a 800 schodech</StyledFigcaption>
                </StyledFigure>
            </DoubleImage>
            <p>Následující den ráno jsme vyrazili přes hraniční přechod Ejlat zpět do Izraele. Cestou na sever jsme navštívili starověkou pevnost <a href="https://cs.wikipedia.org/wiki/Masada">Masada</a> s Herodovým palácem. Zde se Herodes ukrýval před obávanou královnou Kleopatrou a jedná také o místo posledního odporu ve válce židovské. Je považována za symbol izraelské svobody, nezávislosti a heroismu. Na pevnost se dá vyjít pěšky nebo vyjet lanovkou.</p>
            <p>Odpolední hodiny jsme strávili ani ne tak osvěžujícím, jako rozhodně zajímavým zážitkem, a to koupáním v <a href="https://cs.wikipedia.org/wiki/Mrtv%C3%A9_mo%C5%99e">Mrtvém moři</a>. Mrtvé moře se nachází 420 metrů pod úrovní hladiny oceánu. Jedná se o bezodtoké slané jezero. Koncentrace soli dosahuje až 33, 7 %, což jej řadí mezi jedno z nejslanějších jezer planety. Vzhledem k vysoké koncentraci soli se doporučuje koupat se maximálně 20 minut a po té využít jednu z místních plážových sprch, které z vás sůl spláchnou.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/israel/israel4.jpeg" alt="Mrtvé moře"></StaticImage>
                <StyledFigcaption>Mrtvé moře</StyledFigcaption>
            </StyledFigure>
            <p>Další dny zahrnovaly prohlídku města Betléma - Olivovou horu, chrám Dominus Flevit, Getsemanskou zahradu a jeskyni, chrám Hrobu Panny Marie, chrám Smrtelné úzkosti (bazilika Národů) a poté absolvování části Křížové cesty (Via Dolorosa), po které Ježíš kráčel na Kalvarii. Dalšími zastávkami byl chrám Odsouzení a Bičování Krista, Golgota - chrám Božího hrobu, místo ukřižování a vzkříšení Ježíše Krista. Jericho - nejstarší a nejníže položené město na Zemi dobyté Jozuem Boží mocí. Zahrnut byl i výstup ke skalnímu klášteru do míst, kde se Kristus postil a připravoval na své veřejné poslání na <a href="https://cs.wikipedia.org/wiki/Hora_Poku%C5%A1en%C3%AD">horu Pokušení</a>.</p>
            {/* <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/israel/israel5.jpeg" alt="Výhled z Olivové hory na židovský hřbitov a v dálce na celý Jeruzalém"></StaticImage>
                    <StyledFigcaption>Výhled z Olivové hory na židovský hřbitov a v dálce na celý Jeruzalém</StyledFigcaption>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/israel/israel6.jpeg" alt="Getsemanská zahrada, podle Bible zde Ježíš se svými apoštoly bděl a modlil se tu noc, kdy byl zatčen a později ukřižován"></StaticImage>
                    <StyledFigcaption>Getsemanská zahrada, podle Bible zde Ježíš se svými apoštoly bděl a modlil se tu noc, kdy byl zatčen a později ukřižován</StyledFigcaption>
                </StyledFigure>
            </DoubleImage> */}
            <p>Zajímavost sedmého dne byla návštěva kláštera Mar Saba, kde jsou uloženy ostatky sv. Sáby. Mužská část osazenstva naší turistické výpravy měla štěstí a mohla se tak vydat i na jeho vnitřní prohlídku. I přes to, že se nejedná  pro turistické návštěvníky o automatický bod programu, protože nikdy není předem jisté, že místní mnichové, kteří v klášteře žijí, návštěvníky na prohlídku přijmou.. Ženy mají vstup do kláštera zapovězený</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/israel/israel7.jpeg" alt="Klášter Mar Saba, kam je ženám vstup zapovězen"></StaticImage>
                <StyledFigcaption>Klášter Mar Saba, kam je ženám vstup zapovězen</StyledFigcaption>
            </StyledFigure>
            <p>Pokud při svém cestování budete mít čas určitě nevynechejte plavbu po Galilejském jezeře a ochutnávku <a href="https://irybarstvi.cz/zazracne-biblicke-ryby-co-vlastne-ulovil-svaty-petr-patron-rybaru/">Tilápie Galilejské</a>. Za zmínku také stojí <a href="https://cs.wikipedia.org/wiki/Bejt_%C5%A0e%27an">Beit Shean</a>, <a href="https://www.karetatour.cz/pruvodce-po-izraeli/kafarnaum-v-galileji-misto-jezisovych-zazraku.html">Kafarnaum</a>, Yardenit <i>(pro vysvětlení jedná se o křestní místo u řeky Jordán)</i>, také známé jako Yardenit Baptismal Site, místo křtu nacházející se v regionu Galilea v severním Izraeli, které je navštěvováno křesťanskými poutníky.</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/israel/israel8.jpeg" alt="Tilápie galilejská"></StaticImage>
                    <StyledFigcaption>Tilápie galilejská</StyledFigcaption>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/israel/israel9.jpeg" alt="Řeka Jordán, historicky a nábožensky je považována za jednu z nejposvátnějších řek světa"></StaticImage>
                    <StyledFigcaption>Řeka Jordán, historicky a nábožensky je považována za jednu z nejposvátnějších řek světa</StyledFigcaption>
                </StyledFigure>
            </DoubleImage>
            <p>Poslední dny patřily návštěvě Tel Avivu, který mi naprosto učaroval kombinací městské pláže a v té době teprve vystavovaných výškových budov. V hlavě jsem si tak představovala, jaké to asi musí být v Miami. No ještě jsem tam nebyla, takže ten pocit prozatím nedokážu porovnat. Ale třeba jednou..</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/israel/israel10.jpeg" alt="Tel Aviv"></StaticImage>
                <StyledFigcaption>Tel Aviv</StyledFigcaption>
            </StyledFigure>
            <p>A v neposlední řadě následovalo dokončení prohlídky města Jeruzaléma a jeho Chrámové hory, na níž je umístěna hlavní dominanta města mešita Al-Aksá. Nasátí večerní atmosféry u zdi nářků a ranní rozloučení s našim deseti denním drobro-družství, které proběhlo u <a href="https://cs.wikipedia.org/wiki/Izraelsk%C3%A1_bezpe%C4%8Dnostn%C3%AD_bari%C3%A9ra">Izraelské bezpečnostní bariéry</a>.</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/israel/israel11.jpeg" alt="Chrámová hora"></StaticImage>
                    <StyledFigcaption>Chrámová hora</StyledFigcaption>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/israel/israel12.jpeg" alt="Mešita Al-Aksá"></StaticImage>
                    <StyledFigcaption>Mešita Al-Aksá</StyledFigcaption>
                </StyledFigure>
            </DoubleImage>
            <p>Ke Zdi nářků přichází mnoho, nejen židovských, poutníků z celého světa, kteří přichází, aby se zde pomodlili. Typickým zvykem doprovázejícím modlitby je vkládání malého papírku s modlitbami nebo přáními mezi kameny, ze kterých je zeď postavena.</p>
            <p>V době, kdy jsme v Jeruzalémě u Zdi nářků byli my, byla modlitební část rozdělena na mužskou (poměrně větší) a ženskou část. I když to vypadalo, že v následujících letech dojde k propojení obou částí, v roce 2017 byl tento plán zastaven Izraelskou vládou.</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/israel/israel13.jpeg" alt="Zeď nářků"></StaticImage>
                    <StyledFigcaption>Zeď nářků</StyledFigcaption>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/israel/israel14.jpeg" alt="Rozdělení modlitebního prostoru před Zdí nářků, vlevo část mužská a vpravo ženská"></StaticImage>
                    <StyledFigcaption>Rozdělení modlitebního prostoru před Zdí nářků, vlevo část mužská a vpravo ženská</StyledFigcaption>
                </StyledFigure>
            </DoubleImage>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/israel/israel15.jpeg" alt="Symbol Izraelsko-Palestinského konfliktu, tzv. Izraelská bezpečnostní bariéra, která je budovaná od roku 2003 za účelem ochrany izraelských civilistů před palestinským terorismem"></StaticImage>
                <StyledFigcaption>Symbol Izraelsko-Palestinského konfliktu, tzv. Izraelská bezpečnostní bariéra, která je budovaná od roku 2003 za účelem ochrany izraelských civilistů před palestinským terorismem</StyledFigcaption>
            </StyledFigure>
            <p>V závěru mohu doporučit jednu z knih, která nám byla taktéž doporučena a napsal ji český politolog <a href="https://www.databazeknih.cz/knihy/izrael-a-palestina-9411">Marek Čejka - Izrael a Palestina</a>.</p>
            <p>Cena zájezdu u CK byla v roce 2017 27 000 Kč a musím říct, že mi to za to rozhodně stálo. V této ceně jsme měli zahrnuty: letenky, autobusovou přepravu, 8x ubytování vč. polopenze, 2x speciální oběd, služby průvodce, posezení u vína Cremisan a povinné pojištění.</p>
            <p>Na místě jsme dopláceli cca 170 USD na osobu na vstupné a bakšiše pro řidiče. Dokupovali jsme si akorát pití a v případě hladu obědy, ale těch až tolik nebylo. Rozhodně jako suvenýr doporučuji koupit místní datle, na které při procházkách města, tržnicích nebo různých zastávkách určitě narazíte.</p>
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
